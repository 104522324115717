<template>
  <div class="about-us" v-loading="loading">
    <div class="u-container">
      <div class="c-hd">
        <a class="c-logo" href="/home">
          <img src="../../assets/images/aboutus_log.gif" alt="" />
        </a>
      </div>
      <div class="c-bd">
        <div class="b-menu">
          <div class="b-title">{{ toptitle }}</div>
            <ul class="m-container">
              <li class="m-item" :class="item.id == twoid ? 'm-state' : ''" v-for="item in leftlist" :key="item.id" @click="TwoCate(item)">
                <span>{{ item.title }}</span>
                  <i class="fa fa-chevron-right"></i>
              </li>
            </ul>
          </div>
          <div class="b-content">
            <div class="c-title m-title">
              <span>{{ Twotitle }}</span>
            </div>
            <div class="b-container">
              <div class="l-content">
                <ul class="b-list clearfix" v-if="leftlan">
                  <li class="l-item" :class="item.id == cenid ? 'l-state' : ''" v-for="item in titlist" :key="item.id" @click="CentonCate(item)">
                    {{ item.art_title }}
                </li>
                </ul>
                <div class="l-title">
                  <span>{{ centitle }}</span>
                </div>
                <div class="l-text" v-html="texthtml"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="c-footer">
        <div class="f-main">
          <div class="m-ul">
            <div class="m-item">
              <a href="/home">主页</a>
            </div>
            <div class="m-item">
              <a href="/home/about?id=38">十二方产业</a>
            </div>
            <!-- <div
            class="m-item"
            v-for="item in articleCategoryList"
            :key="item.id"
          >
            <router-link :to="`/home/about?id=${item.id}`">{{
              item.title
            }}</router-link>
          </div> -->
            <div class="m-item">
              <a href="/home/admin_login" target="_blank">管理者登录</a>
            </div>
            <div class="m-item">
              <a href="https://serfoo.com/school/pc/home" target="_blank">职业介绍</a>
            </div>
          </div>
          <div class="m-txt">
            <a href="https://beian.miit.gov.cn/" target="_blank">湘ICP备2021002198号-1</a>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
  import { getArticleCategoryApi, TwoCateApi, getArticleApi } from '@/api/api'
  export default {
    name: "about",
    data() {
      return {
        id: '',
        cenid: '', // 当前文章
        centitle: '',
        twoid: 0, // 当前分类
        Twotitle: '',
        leftlan: true,
        leftlist: [],
        titlist: [],
        articleCategoryList: [],
        loading: false,
        texthtml: '',
        toptitle: '网站导航',
      }
    },
    created() {
      this.getArticleCategory()
    },
    watch: {
      $route(to, from) {
        if (this.$route.query.id && from.path == "/home/about") {
          this.getArticleCategory();
        }
      }
    },
    methods: {
      async getArticleCategory() { // 底部菜单获取
        this.loading = true;
        const { data: res } = await getArticleCategoryApi(0);
        if (res.status == 90000) { this.$router.push('/home/error'); return false; }
        this.articleCategoryList = res.result
        this.leftlist = res.result;
        for (var i = 0; i < this.leftlist.length; i++) {
          if (this.$route.query.id == this.leftlist[i].id) {
            this.TwoCate(this.leftlist[i])
          }
        }
        this.loading = false;
      },
      async TwoCate(val) { // 获取指定分类下的所有文章
        this.twoid = val.id;
        this.Twotitle = val.title;
        window.history.replaceState(null, null, `/home/about?id=${val.id}`);
        this.loading = true;
        const { data: res } = await TwoCateApi(val.id);
        if (res.status == 90000) { this.$router.push('/home/error'); return false; }
        this.titlist = res.result;
        this.loading = false;
        if (this.titlist.length > 0) {
          this.CentonCate(this.titlist[0])
        } else {
          this.centitle = '该栏目下暂无文章';
          this.texthtml = '该栏目下暂无文章';
        }
      },
      async CentonCate(val) { // 获取文章内容
        this.cenid = val.id;
        this.centitle = val.art_title;
        // this.loading = true;
        const { data: res } = await getArticleApi(val.id);
        if (res.status == 90000) { this.$router.push('/home/error'); return false; }
        this.texthtml = res.result.content;
        // this.loading = false;
      }
    },
  }
</script>
<style>
  table {
    color: #333333;
    border-width: 1px;
    border-color: #ddd;
    border-collapse: collapse;
  }

  table th {
    border-width: 1px;
    padding: 8px;
    border-style: solid;
    border-color: #ddd;
    background-color: #dedede;
  }

  table td {
    border-width: 1px;
    padding: 8px;
    border-style: solid;
    border-color: #ddd;
    background-color: #ffffff;
  }

  .l-text p {
    width: 100%;
    height: auto;
    padding: 0px;
    margin: 0px;
  }

  .l-text img {
    vertical-align: bottom;
    border: none;
    max-width: 100% !important;
    display: block;
    margin: 0;
    padding: 0;
  }
</style>
<style lang="less" scoped>
  .about-us {
    background: #fff;

    .u-container {
      width: 1200px;
      margin: 0px auto;
      min-height: 800px;
      box-sizing: border-box;

      .c-hd {
        .c-logo {
          width: 1200px;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      .c-bd {
        display: flex;
        margin-top: 20px;

        .b-title {
          font-size: 18px;
          height: 40px;
          line-height: 40px;
          text-align: right;
          padding-right: 40px;
          background: #eee;
          font-weight: 600;
        }

        .m-title {
          font-size: 18px;
          height: 40px;
          line-height: 40px;
          text-align: right;
          padding-right: 40px;
          background: #eee;
          font-weight: 600;
        }

        .b-menu {
          width: 200px;
          min-height: 200px;
          padding-right: 20px;

          .m-container {
            .m-item {
              width: 200px;
              box-sizing: border-box;
              height: 60px;
              line-height: 60px;
              text-align: right;
              font-size: 16px;
              border-bottom: 1px solid #ddd;
              padding: 0px 20px;
              cursor: pointer;
              position: relative;

              span {
                display: block;
                margin-right: 20px;
              }

              i {
                font-size: 12px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 20px;
              }

              &:hover {
                color: #cb0000;
              }
            }

            .m-state {
              color: #cb0000;
            }
          }
        }

        .b-content {
          width: 980px;

          .c-title {
            text-align: left;
            padding: 0px 40px;
          }

          .b-container {
            display: flex;

            .l-content {
              width: 980px;
              border-top: none;

              .b-list {
                padding: 20px 40px;

                .l-item {
                  width: 25%;
                  float: left;
                  font-size: 14px;
                  text-align: left;
                  box-sizing: border-box;
                  cursor: pointer;
                  margin-bottom: 20px;

                  &:hover {
                    color: #cb0000;
                  }
                }

                .l-item:last-child {
                  margin-bottom: 0px;
                }
              }

              .l-title {
                font-size: 16px;
                border-bottom: 1px solid #ddd;
                padding: 10px 40px;
                font-weight: 600;
                color: #cb0000;
              }

              .l-text {
                padding: 40px;
                font-size: 14px;
                line-height: 1.6;
              }
            }
          }
        }
      }
    }

    .c-footer {
      width: 1200px;
      margin: 0 auto;
      margin-top: 40px;
      border-top: 40px solid #eee;
      font-size: 14px;

      .f-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 0px;

        .m-ul {
          display: flex;
          align-items: center;

          .m-item {
            height: 20px;
            box-sizing: border-box;
            line-height: 20px;
            border-right: 1px solid #ddd;
            text-align: center;
            padding: 0px 10px;
          }

          .m-item:last-child {
            border-right: none;
          }
        }

        .m-txt {
          line-height: 40px;
          text-align: center;
          font-size: 13px;
        }
      }
    }
  }
</style>